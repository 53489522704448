import { atom, selector } from 'recoil';
import { IFirmInfo } from '../lib/types';
import api from '../lib/api';

export const firmInfoSelector = selector({
   key: 'firmInfoSelector',
   get: async (): Promise<IFirmInfo[]> => {
      const firmInfo: IFirmInfo[] | null = await api.getFirmInfo();

      // Optionally process the firm info if needed
      return firmInfo ? firmInfo : []; // Return an empty array if null
   },
});

export const firmInfoAtom = atom({
   key: 'firmInfo', // unique ID (with respect to other atoms/selectors)
   default: firmInfoSelector, // default value (aka initial value)
});
