import { atom, selector } from 'recoil';
import { IAffiliateShort, Sort, SortDirection } from '../lib/types';
import { adminAffiliatesSelector, organizationNameAtom } from './admin';
import { adminFirmFiltersAtom } from './admin-firm-search';
import { sortDirectionAtom } from './customers-sort-filter';
import { adminCityFiltersAtom } from './admin-city-search';
import { adminStateFiltersAtom } from './admin-state-search';

// currently applied sort
export const adminSortAtom = atom<Sort>({
   key: 'AdminAffiliateListSort',
   default: Sort.default,
});

// filters affiliates list based on firm, city, state
export const filteredAffiliatesSelector = selector({
   key: 'FilteredAffiliateList',
   get: ({ get }): IAffiliateShort[] => {
      const firmFilters: string[] = get(adminFirmFiltersAtom);
      const cityFilters: string[] = get(adminCityFiltersAtom);
      const stateFilters: string[] = get(adminStateFiltersAtom);
      let affiliates: IAffiliateShort[] | null = get(adminAffiliatesSelector);

      if (firmFilters.length > 0) {
         affiliates = affiliates.filter((a) =>
            firmFilters.some(
               (f) => f.toLowerCase() === a.organizationName.toLowerCase()
            )
         );
      }

      if (cityFilters.length > 0) {
         affiliates = affiliates.filter((a) =>
            cityFilters.some(
               (f) => f.toLowerCase() === a.organizationCity.toLowerCase()
            )
         );
      }

      if (stateFilters.length > 0) {
         affiliates = affiliates.filter((a) =>
            stateFilters.some(
               (f) => f.toLowerCase() === a.organizationState.toLowerCase()
            )
         );
      }
      return affiliates;
   },
});

// sorts filtered affiliates list based on adminSortAtom and sortDirectionAtom
// export const filteredSortedAffiliatesSelector = selector({
//    key: 'FilteredSortedAdminAffiliateList',
//    get: ({ get }): IAffiliateShort[] => {
//       const sort = get(adminSortAtom);
//       const affiliates = get(filteredAffiliatesSelector);
//       const direction = get(sortDirectionAtom);

//       let arrayForSort = [...affiliates];

//       switch (sort) {
//          case Sort.firstName:
//             arrayForSort = arrayForSort.sort((a, b) =>
//                a.firstName.localeCompare(b.firstName)
//             );
//             return direction == SortDirection.ascending
//                ? arrayForSort
//                : arrayForSort.reverse();

//          case Sort.lastName:
//             arrayForSort = arrayForSort.sort((a, b) =>
//                a.lastName.localeCompare(b.lastName)
//             );
//             return direction == SortDirection.ascending
//                ? arrayForSort
//                : arrayForSort.reverse();

//          default:
//             return affiliates;
//       }
//    },
// });
export const filteredSortedAffiliatesSelector = selector({
   key: 'FilteredSortedAdminAffiliateList',
   get: ({ get }): IAffiliateShort[] => {
      const sort = get(adminSortAtom);
      const affiliates = get(adminAffiliatesSelector); // Get already filtered affiliates
      const direction = get(sortDirectionAtom);

      // Apply sorting based on the selected criteria
      let sortedAffiliates = [...affiliates]; // Clone the array to avoid mutating state

      switch (sort) {
         case Sort.firstName:
            sortedAffiliates.sort((a, b) => a.firstName.localeCompare(b.firstName));
            break;

         case Sort.lastName:
            sortedAffiliates.sort((a, b) => a.lastName.localeCompare(b.lastName));
            break;

         default:
            // No sorting criteria matched; return affiliates as is
            return sortedAffiliates;
      }

      // Reverse if descending is selected
      return direction === SortDirection.ascending ? sortedAffiliates : sortedAffiliates.reverse();
   },
});

