import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
   useRecoilRefresher_UNSTABLE,
   useRecoilState,
   useRecoilValue,
   useSetRecoilState,
} from 'recoil';
import AdminPaging from '../components/admin-paging/admin-paging';
import AdminSortFilter from '../components/admin-sort-filter/admin-sort-filter';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import CardAffiliate from '../components/card-affiliate/card-affiliate';
import InviteButton from '../components/invite-button/invite-button';
import Search, { ISearchProps } from '../components/search/search';
import api from '../lib/api';
import {
   IAffiliateImpersonation,
   IAffiliateRecent,
   IAffiliateShort,
} from '../lib/types';
import {
   affiliateRecentSelector,
   reloadRecentAffiliates,
} from '../recoil/admin-affiliate-recent';
import { adminImpersonateCookie } from '../recoil/admin-impersonate';
import { adminPagingAtom } from '../recoil/admin-paging';
import {
   adminAffiliatesSearchQueryAtom,
   searchedAffiliatesSelector,
} from '../recoil/admin-search';
import { filteredSortedAffiliatesSelector } from '../recoil/admin-sort-filter';
import './admin.scss';
import { organizationNameAtom } from '../recoil/admin';

export default function AdminPage(): JSX.Element {
   const { organizationName } = useParams<{ organizationName: string }>();
   const setOrganizationName = useSetRecoilState(organizationNameAtom);
   const affiliateRecents: IAffiliateRecent[] = useRecoilValue(affiliateRecentSelector);
   const affiliateRecentRefresh = useRecoilRefresher_UNSTABLE(affiliateRecentSelector);
   const [reloadRecent, setReloadRecent] = useRecoilState(reloadRecentAffiliates);

   const [shouldReload, setShouldReload] = useState(false); // New state to control reload

   // Set the organization name in Recoil state when the organizationName param changes
   useEffect(() => {
      if (organizationName) {
         setOrganizationName(organizationName);
      }
   }, [organizationName, setOrganizationName]);

   const affiliates: IAffiliateShort[] = useRecoilValue(filteredSortedAffiliatesSelector);

   useEffect(() => {
      if (reloadRecent) {
         affiliateRecentRefresh();
         setReloadRecent(false);
      }
   }, [affiliateRecentRefresh, reloadRecent]);

   const searchProps: ISearchProps = {
      placeholderPrimary: 'for an Attorney or Paralegal',
      placeholderSecondary: 'by name',
      searchQueryAtom: adminAffiliatesSearchQueryAtom,
      searchResultSelector: searchedAffiliatesSelector,
      resultPageLink: 'affiliate-details',
      searchById: false,
      affiliateRecents: affiliateRecents,
      disabled: false,
   };

   const cookie: IAffiliateImpersonation | null = useRecoilValue(adminImpersonateCookie);

   // Set shouldReload to true if the cookie exists, triggering a reload in useEffect
   useEffect(() => {
      if (cookie) {
         Cookies.remove('x-affdash-actor');
         setShouldReload(true);
      }
   }, [cookie]);

   // Trigger the reload outside of the render phase
   useEffect(() => {
      if (shouldReload) {
         window.location.reload();
      }
   }, [shouldReload]);

   const currentPage: number = useRecoilValue(adminPagingAtom);

   return (
      <div className="admin">
         <div className="app-body">
            <AppHeader overrideName={false} />
            <Search
               placeholderPrimary={searchProps.placeholderPrimary}
               placeholderSecondary={searchProps.placeholderSecondary}
               searchQueryAtom={searchProps.searchQueryAtom}
               searchResultSelector={searchProps.searchResultSelector}
               resultPageLink={searchProps.resultPageLink}
               searchById={searchProps.searchById}
               affiliateRecents={searchProps.affiliateRecents}
               disabled={false}
               affiliates={affiliates}
            />
            <AdminSortFilter />

            <div className="content-row-wrapper">
               <div className="admin-content">
                  <InviteButton
                     inviteTarget="Affiliate"
                     headerText="Invite a New Affiliate"
                     bodyText="Inviting a new affiliate will send a request to their email."
                     confirmText="Send Request"
                     successTextPre="Request sent to "
                     successTextPost=" email."
                     inviteFunction={api.postInviteAffiliate}
                  />
                  {affiliates
                     .slice(0 + 25 * currentPage, 25 + 25 * currentPage)
                     .map((a) => (
                        <CardAffiliate key={a.id} affiliate={a} />
                     ))}
                  <AdminPaging />
               </div>
            </div>
         </div>
         <AppFooter />
      </div>
   );
}
