import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { firmInfoSelector } from './firm-info';

// currently applied search
export const firmSearchQueryAtom = atom<string>({
   key: 'FirmSearchQuery',
   default: '',
});

// searches firm list based on firmSearchQueryAtom
export const searchedFirmSelector = selector<string[]>({
   key: 'SearchedFirmInfoList',
   get: ({ get }) => {
      const query = get(firmSearchQueryAtom);
      const firmList = get(firmInfoSelector) || []; // Fallback if firmInfoSelector is undefined
      const searchResults: string[] = [];
      const lowercaseSet = new Set();

      const options = {
         includeScore: true,
         threshold: 0.5,
         keys: ['organizationName'],
      };

      const fuse = new Fuse(firmList, options);
      const results = fuse.search(query);

      results.slice(0, 6).forEach((result) => {
         const orgName = result.item.organizationName;
         const lowercaseOrgName = orgName.toLowerCase();

         if (!lowercaseSet.has(lowercaseOrgName)) {
            lowercaseSet.add(lowercaseOrgName);
            searchResults.push(orgName);
         }
      });

      return searchResults;
   },
});

// currently applied firm filters
export const firmInfoFiltersAtom = atom<string[]>({
   key: 'FirmFilters',
   default: [],
});
