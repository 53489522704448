import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IAffiliateImpersonation } from '../../lib/types';
import { adminImpersonateCookie } from '../../recoil/admin-impersonate';
import './impersonate-header.scss';
import TinyButton from '../tiny-button/tiny-button';

interface ImpersonateHeaderProps {
   navigationStep: number; // Accept navigation step as a prop
}

export default function ImpersonateHeader({ navigationStep }: ImpersonateHeaderProps): JSX.Element {
   const navigate = useNavigate();

   const revertToAdmin = (): void => {
      // navigate('/admin');
      navigate(navigationStep);
      Cookies.remove('x-affdash-actor');
      // window.location.reload();
   };

   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );

   if (!cookie) {
      return <div></div>;
   }

   return (
      <nav className="impersonate-header">
         <div
            className="impersonate-header-text body-standard-semibold"
            onClick={() => revertToAdmin()}
         >
            View for
            {' ' + cookie.firstName + ' ' + cookie.lastName + '. '}
            Click here to return to Affiliate Details Page
         </div>
         <TinyButton
            text="Stop Impersonating"
            action={revertToAdmin}
            disabled={false}
         />
      </nav>
   );
}
