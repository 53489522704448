import React from 'react';
import './app-footer.scss';
import IntercomChat from '../intercom-chatbot/intercom-chat';

export interface IAppFooterProps {
   extraHeight?: boolean;
}

export default function AppFooter(props: IAppFooterProps): JSX.Element {
   const cstLogoMonochromeSvg = new URL(
      '/public/images/mindr-bg-white.svg',
      import.meta.url
   );
   const facebookLogoSvg = new URL(
      '/public/images/facebook-logo.svg',
      import.meta.url
   );
   const linkedinLogoSvg = new URL(
      '/public/images/linkedin-logo.svg',
      import.meta.url
   );
   const twitterLogoSvg = new URL(
      '/public/images/twitter-logo.svg',
      import.meta.url
   );
   const youtubeLogoSvg = new URL(
      '/public/images/youtube-logo.svg',
      import.meta.url
   );

   return (
      <footer className="app-footer">
         {/* Intercom Chatbot */}
         <IntercomChat />
         <div className="footer-logos">
            <img
               className="cst-logo-monochrome"
               src={cstLogoMonochromeSvg.toString()}
            />
            <a
               className="footer-link-logo body-small-semibold"
               href="https://www.facebook.com/Intoxalock"
               target="_blank"
               rel="noreferrer"
            >
               <img
                  className="footer-social-logo"
                  src={facebookLogoSvg.toString()}
               />
            </a>
            <a
               className="footer-link-logo body-small-semibold"
               href="https://www.linkedin.com/company/intoxalock"
               target="_blank"
               rel="noreferrer"
            >
               <img
                  className="footer-social-logo"
                  src={linkedinLogoSvg.toString()}
               />
            </a>
            <a
               className="footer-link-logo body-small-semibold"
               href="https://twitter.com/Intoxalock"
               target="_blank"
               rel="noreferrer"
            >
               <img
                  className="footer-social-logo"
                  src={twitterLogoSvg.toString()}
               />
            </a>
            <a
               className="footer-link-logo body-small-semibold"
               href="https://www.youtube.com/intoxalock"
               target="_blank"
               rel="noreferrer"
            >
               <img
                  className="footer-social-logo"
                  src={youtubeLogoSvg.toString()}
               />
            </a>
         </div>
         <div className="footer-links">
            <a
               className="footer-link body-small-semibold"
               href="https://mindrco.com/"
               target="_blank"
               rel="noreferrer"
            >
               Mindr
            </a>
            <a
               className="footer-link body-small-semibold"
               href="https://Intoxalock.com/select"
               target="_blank"
               rel="noreferrer"
            >
               Attorney Advertising
            </a>
            <a
               className="footer-link body-small-semibold"
               href="https://www.intoxalock.com/privacy-policy/"
               target="_blank"
               rel="noreferrer"
            >
               Privacy Notice
            </a>
            <a
               className="footer-link body-small-semibold"
               href="https://www.intoxalock.com/california-privacy-policy/"
               target="_blank"
               rel="noreferrer"
            >
               State Privacy Notices
            </a>
            <a
               className="footer-link body-small-semibold"
               href="https://www.intoxalock.com/california-privacy-policy/#ccpasubmit"
               target="_blank"
               rel="noreferrer"
            >
               Do Not Sell or Share My Personal Information
            </a>
            <a
               className="footer-link body-small-semibold"
               href="https://www.intoxalock.com/privacy-policy/terms-of-use/"
               target="_blank"
               rel="noreferrer"
            >
               Terms of Use
            </a>
         </div>
         <div className="footer-copyright label-xsmall-allcaps">
            Copyright © 2023. All Rights Reserved.
         </div>
         {props.extraHeight && <div className="footer-extra-height"></div>}
      </footer>
   );
}
