import React from 'react';
import { RecoilState } from 'recoil';
import { ISortType, Sort } from '../../lib/types';
import SortDropdownItem from '../sort-dropdown-item/sort-dropdown-item';
import TinyButton from '../tiny-button/tiny-button';
import './sort-dropdown.scss';

export interface ISortDropdownProps {
   dropdownRef: React.MutableRefObject<null>;
   className: string;
   sorts: ISortType[];
   sortAtom: RecoilState<Sort>;
   onSortSelect: (newSort: Sort) => void;
   clearSort: () => void;
}

export default function SortDropdown(props: ISortDropdownProps): JSX.Element {
   return (
      <div
         className={`sort-dropdown ${props.className}`}
         ref={props.dropdownRef}
      >
         <div className="dropdown-header">
            <div className="body-small-semibold">Sort</div>
            <TinyButton
               action={props.clearSort}
               disabled={false}
               text="Clear"
            />
         </div>
         {props.sorts.map((sort, index) => (
            <SortDropdownItem
               key={index}
               text={sort.sort}
               ascDesc={sort.ascendingDescending}
               sortAtom={props.sortAtom}
               onSortSelect={() => props.onSortSelect(sort.sort)}  // Pass onSortSelect with sort value
            />
         ))}
      </div>
   );
}
