import { atom, selector } from 'recoil';
import { IFirmInfo, Sort, SortDirection } from '../lib/types';
import { firmInfoSelector } from './firm-info';
import { firmInfoFiltersAtom } from './firm-search';

// Currently applied sort for the firm list
export const firmSortAtom = atom<Sort>({
   key: 'FirmListSort',
   default: Sort.default,  // Default sort is by organizationName
});

// Sort direction for the firm list
export const firmSortDirectionAtom = atom<SortDirection>({
   key: 'FirmListSortDirection',
   default: SortDirection.ascending,  // Default sorting direction is ascending
});

// Selector to filter and sort the firm list based on organization name
export const filteredFirmSelector = selector({
   key: 'FilteredFirmList',
   get: ({ get }): IFirmInfo[] => {
      const firmFilters = get(firmInfoFiltersAtom);
      const firmList = get(firmInfoSelector) || [];
      const sort = get(firmSortAtom);
      const direction = get(firmSortDirectionAtom);

      // Make a copy of firmList to avoid mutating the original array
      let filteredFirms = [...firmList];

      // Apply filters
      if (firmFilters.length > 0) {
         filteredFirms = filteredFirms.filter((firm) =>
            firmFilters.some((filter) =>
               filter.toLowerCase() === firm.organizationName.toLowerCase()
            )
         );
      }

      // Apply sorting based on the selected sort field (organizationName)
      if (sort === Sort.organizationName) {
         filteredFirms.sort((a, b) => {
            // Normalize and compare organization names
            const normalizedA = a.organizationName.toLowerCase();
            const normalizedB = b.organizationName.toLowerCase();

            const comparison = normalizedA.localeCompare(normalizedB);
            
            // Adjust the comparison result based on the direction
            if (direction === SortDirection.descending) {
               return comparison * -1; // Multiply by -1 for descending;  // Reverse the comparison for descending order
            }

            return comparison;  // Default to ascending order
         });
      }

      return filteredFirms;
   },
});




