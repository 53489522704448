import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './app.scss';
import ProtectedRoute from './components/protected-route/protected-route';
import { isAdmin, isAffiliate } from './lib/authorization';
import { adClient } from './lib/http-client';
import insights from './lib/insights';
import AdminPage from './pages/admin';
import AffiliatePage from './pages/affiliate';
import AffiliateDetailsPage from './pages/affiliate-details';
import CustomerDetailsPage from './pages/customer-details';
import DevPage from './pages/dev';
import PostLoginPage from './pages/postlogin';
import ProfilePage from './pages/profile';
import WelcomePage from './pages/welcome';
import FirmInfoPage from './pages/firmInfo';

export function App(): JSX.Element {
   insights.enableInsights();
   return (
      <MsalProvider instance={adClient}>
         <HashRouter>
            <Routes>
               <Route path="/" element={<WelcomePage />} />
               <Route path="/postlogin" element={<PostLoginPage />} />
               <Route
                  path="/firm-info"
                  element={
                     <ProtectedRoute
                     permission={isAdmin}
                     subRoute={<FirmInfoPage />}
                     />
                  }
               />
               <Route path="/client-details">
                  <Route
                     path=":id"
                     element={
                        <ProtectedRoute
                           permission={isAffiliate}
                           subRoute={<CustomerDetailsPage />}
                        />
                     }
                  />
               </Route>
               <Route path="/admin">
                  <Route path=":organizationName?"
                     element={
                        <ProtectedRoute
                           permission={isAdmin}
                           subRoute={<AdminPage />}
                        />
                     }
                  />
               </Route>
               {/* <Route path="/admin"
                  element={
                     <ProtectedRoute
                        permission={isAdmin}
                        subRoute={<AdminPage />}
                     />
                  }
               /> */}
               <Route path="/affiliate-details">
                  <Route
                     path=":id"
                     element={
                        <ProtectedRoute
                           permission={isAdmin}
                           subRoute={<AffiliateDetailsPage />}
                        />
                     }
                  />
               </Route>
               <Route path="/profile" element={<ProfilePage />} />
               <Route
                  path="/dev"
                  element={
                     <ProtectedRoute
                        permission={isAdmin}
                        subRoute={<DevPage />}
                     />
                  }
               />
               <Route
                  path="/affiliate"
                  element={
                     <ProtectedRoute
                        permission={isAffiliate}
                        subRoute={<AffiliatePage />}
                     />
                  }
               />
            </Routes>
         </HashRouter>
      </MsalProvider>
   );
}
