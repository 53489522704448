import React from 'react';
import AppHeader from '../components/app-header/app-header';
import AppFooter from '../components/app-footer/app-footer';
import FirmDetails from '../components/firm-details/firm-details';
import { IFirmInfo } from '../lib/types';
import { useRecoilValue } from 'recoil';
import { firmInfoAtom } from '../recoil/firm-info';
import FirmPaging from '../components/firm-paging/firm-paging';
import { firmPagingAtom } from '../recoil/firm-paging';
import FirmSortFilter from '../components/firm-sort-filter/firm-sort-filter';
import { filteredFirmSelector } from '../recoil/firm-sort-filter';

export default function FirmInfoPage(): JSX.Element {
   const firm = useRecoilValue(filteredFirmSelector);
   const currentPage: number = useRecoilValue(firmPagingAtom);

    return (
        <div className="admin">
         <div className="app-body">
            <AppHeader overrideName={false} />
            <FirmSortFilter />

            <div className="content-row-wrapper">
               <div className="admin-content">
                  {firm
                     .slice(0 + 25 * currentPage, 25 + 25 * currentPage)
                     .map((info) => (
                        <FirmDetails key={info.organizationName} firm={info} />
                  ))}
                  <FirmPaging />
               </div>
            </div>
         </div>
         <AppFooter />
      </div>
    )
}
