import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { Sort, SortDirection } from '../../lib/types';
import { sortDirectionAtom } from '../../recoil/customers-sort-filter';
import './sort-dropdown-item.scss';

export interface ISortDropdownItemProps {
   text: string;
   ascDesc: boolean;
   sortAtom: RecoilState<Sort>;
   onSortSelect: (newSort: Sort) => void; // Add this prop
}

export default function SortDropdownItem(
   props: ISortDropdownItemProps
): JSX.Element {
   const [sort, setSort] = useRecoilState(props.sortAtom);
   const [sortDirection, setSortDirection] = useRecoilState(sortDirectionAtom);

   const handleChange = (newSort: Sort): void => {
      if (sort === newSort) {
         // Toggle direction when the same sort is clicked
         if (props.ascDesc && sortDirection === SortDirection.ascending) {
            setSortDirection(SortDirection.descending);
         } else {
            setSort(Sort.default);
            setSortDirection(SortDirection.ascending);
         }
      } else {
         setSort(newSort);
         setSortDirection(SortDirection.ascending);
      }
      // Trigger the onSortSelect callback
      props.onSortSelect(newSort);
   };

   const getArrow = (): string => {
      if (!props.ascDesc) return ''; // Early exit if ascending/descending option isn’t enabled

      if (sort === props.text) {
         if (sortDirection === SortDirection.ascending) {
            return 'up';
         } else if (sortDirection === SortDirection.descending) {
            return 'down';
         }
      }

      // Default return if conditions don't match
      return '';
   };

   return (
      <label
         className={`sort-dropdown-item body-large-regular ${
            sort === props.text
               ? 'sort-dropdown-item-checked'
               : 'sort-dropdown-item-not-checked'
         }`}
      >
         {props.text}
         <div
            className={`sort-arrow ${props.ascDesc ? 'ascDesc' : ''} ${getArrow()}`}
         ></div>
         <input
            className="checkbox"
            type="checkbox"
            onChange={() => handleChange(props.text as Sort)} // Trigger handleChange
            checked={sort === props.text}
         />
      </label>
   );
}
